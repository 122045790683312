<template>
    <div id="contentwrap">
        <PageTitle />
        <div id="kestrel-container"></div>
    </div>
</template>
    
<script>
import PageTitle from "../components/PageTitle"
import ihfKestrelService from '@/services/ihfService'; // Adjust the path based on your project structure
export default {
    name: "Search",
    components: {
        PageTitle,
    },
    data(){
        return{
            name:'',
            email: '',
            phone: '',
            test: '',
        }
    },
    computed:{
        formValid(){
            return this.name && this.email && this.phone
        }
    },
    metaInfo: {
        title: 'Search ',
        titleTemplate: '%s Meredith Schlosser',
        meta: [
            {
                name: 'description',
                content: "About Meredith Schlosser - Real Estate Agent: Outgoing, Tireless, and a Commitment to Excellence. She's the Director of Aaroe International Luxury Properties and a member of the SFJ Group."
            },
            {
                name: 'author',
                content: "Meredith Schlosser"
            },
        ]
    },
    mounted(){
        this.initializeKestrel();
    },
    methods: {
        initializeKestrel() {
            ihfKestrelService.configureKestrel();

            // Select the container element by ID
            const container = document.getElementById('kestrel-container');

            // Check if the container element is found
            if (container) {
                container.innerHTML = ''; // Clear existing content if any
                container.appendChild(window.ihfKestrel.render());
            } else {
                console.error('Container element not found.');
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.content-container{
    padding: 80px 0;
}
header{
    text-align: center;
}
.content-section, .content-section-reverse{
    max-width: 1020px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px auto;
    padding-top: 60px;
    border-top: 1px solid #ccc;
}
.content-section-reverse{
    flex-direction: row-reverse;
}

.paragraph{
    p{
        padding: 0 40px;
    }
}

.img-container{
    padding-left: 40px;
    img{
        display: block;
        width: 100%;
    }
}

.items-container{
    margin-left: 40px;
    li{
        font-family: "Oswald",Helvetica,Arial,sans-serif;
        margin: 8px 0;
        font-size: 25px;
        font-weight: 500;
        text-transform: uppercase;
        list-style-image: url(/images/check.png);
        max-width: 280px;
        padding-left: 1px;
    }
}

.form-container{
    margin:0 40px;
}

#subscripe{
    background: #a9976f;
    .form-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 60px;
    }
    .form-elements{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    h2{
        color: #fff;
    }
}


.form-elements > input{
    background: #e1dbcd;
    border: none;
    padding-left: 20px;
    &::placeholder{
        color: #000;
    }
}

.button{
    color: #fff;
    background: #000;
    padding: 0 40px;
}

input[type=submit][disabled=disabled]{
    cursor: not-allowed;
    color: #ccc;
}

@media (max-width: $mobile) {
    .content-section, .content-section-reverse{
        flex-direction: column;
    }
    .img-container{
        padding: 0 40px;
    }
    #subscripe{
        .form-container{
            padding: 20px 10px;
            margin:0 10px;
        }
    }
    .form-elements{
        min-width: 340px;
    }

    .items-container{
        margin-left: unset;
    }
}
</style>